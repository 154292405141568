/* ================ ANIMACIONES ================= */
/* Entrada */
@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
/* Salida */
@-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-3000px);
              transform: translateX(-3000px);
      opacity: 1;
    }
  }
  
/* =============================================== */
header {
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s all ease-in;
    background-color: var(--dark); /* Transparent */
}
.colorchange {
  transition: .2s all ease-in;
  background-color: #000;
}
.logoJadZo {
    width: 50px;
    margin-left: 20px;
}
.menu-btnOpen > img {
    width: 25px;
}
.menu-btnOpen {
    margin-right: 20px;
}
.nav-bar {
    position: fixed;
    list-style: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -3;
    -webkit-animation: slide-out-left 0.9s linear both;
	  animation: slide-out-left 0.9s linear both;
    overflow-y: none;
    background-color: #644892;
    background-image: linear-gradient(180deg, #644892 0%, #171c22 100%);
}
.nav-bar__active {
    position: fixed;
    z-index: 10;
    -webkit-animation: slide-in-left 0.9s ease both;
	  animation: slide-in-left 0.59s ease both;
}
.sections-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    row-gap: 25px;
    position: relative;
    z-index: 11;
}
.sections-list li {
    display: flex;
    justify-content: center;
    width: 100%;
    outline: none;
}
.sections-list li a {
  cursor: none;
  text-decoration: none;
  font-size: 2.2rem;
  font-weight: 300;
  color: var(--white);
}
.logoSideBarNR {
    width: 70px;
    position: absolute;
    bottom: 40px;
}
@media (min-width: 1024px) {
    .logoHeader {
        margin-left: 30px;
    }
    .menu-btnOpen {
        display: none;
    }
    .nav-bar {
        position: relative;
        width: auto;
        height: auto;
        flex-direction: row;
        -webkit-animation: none;
        animation: none;
        background-color: transparent;
        background-image: none;
    }
    .sections-list {
        flex-direction: row;
        justify-content: right;
        gap: 4vw;
        margin-right: 40px;
    }
    .sections-list  li {
        width: auto;
        justify-content: space-evenly;
    }
    .sections-list li a {
        cursor: pointer;
        font-size: 1.8rem;
    }
    .logoSideBarNR {
        display: none;
    }
}

