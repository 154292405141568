.copy {
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #644892;
}
.copy > p {
    font-size: 1rem;
    font-weight: bold;
    color: var(--white);
}
@media(min-width: 768px) {
    .copy > p {
        font-size: 1.4rem;
    }
}