@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Fenomen Sans Black";
  src: url("./assets/fonts/FenomenSans-Black.ttf");
}
:root {
  /* Colors #1 */
  --dark: #000000;
  --dark-blue: #0f1d36;
  --soft-blue: #132744;
  --warm-blue: #16215b;
  --blue: #1645d3;
  --green-caine: #e1ff2d;
  --white: #fff;
  /* Font */
  --font-title: "Fenomen Sans Black";
  --ss: 4.5rem;
  --sm: 8rem;
  --sl: 9rem;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
.no-scroll {
  overflow: hidden;
  position: fixed;
}