@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse {
    to {
      transform: scale(0.9);
      opacity: 1;
    }
}
.Loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    -webkit-animation: fade-out 4s ease-in-out both;
	animation: fade-out 4s ease-in-out both;
    background-color: #000;/* #644892 */
}
.logo-loader > img {
    width: 40vw;
    max-width: 200px;
}
.loader{
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    animation: pulse 1s ease-in-out infinite alternate;
    text-shadow: 0 0 1px white;
}
.loaderOff {
    display: none;
}